.select-custom {
  position: relative;
  color: $color-grey;
  padding: $space-10;
  background-color: $color-grey-light;
  border-radius: $radius-12;
  cursor: pointer;

  &:after {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    content: "\e912";
    font-family: 'icomoon';
    font-size: $font-size-16;
    color: $color-grey-dark;
  }
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider,
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  outline: none;
  background-color: $color-primary;
}

.p-inputswitch .p-inputswitch-slider {
  box-shadow: none;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  box-shadow: none;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background-color: $color-white;
}

// CHECKBOX //
.p-checkbox {
  display: flex;
  align-items: center;
  height: 16px;
}

.p-checkbox .p-checkbox-box {
  display: flex;
  align-items: center;
  background: $color-white;
  border: 1px solid $color-black;
  width: 15px;
  height: 15px;
  border-radius: 4px;

  &:focus,
  &:hover,
  &:checked {
    box-shadow: none;
    background: $color-white;
    // border: none;
    border: 1px solid $color-primary !important;
  }
}
.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
    background: $color-primary;
    border-color: transparent;
}

.p-checkbox .p-checkbox-box.p-highlight {
  background: $color-primary;
  width: 16px;
  height: 16px;
  border: none;
}

.p-checkbox .pi-check {
  display: flex;
  align-items: center;
}

.p-checkbox .pi-check::before,
.p-checkbox .p-checkbox-box .p-checkbox-icon {
  font-size: $font-size-10;
  color: $color-black;
}

p-dropdown {
  max-width: 100%;

  .p-dropdown {
    border-radius: 12px;
    border: none;
    width: $width-input;
    background: $color-grey-medium;

    &:not(.p-disabled).p-focus {
      outline-color: $color-primary !important;
    }
  }

  .p-dropdown:not(.p-disabled).p-focus {
    box-shadow: none;
  }

  .p-dropdown .p-inputtext {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  .p-dropdown .p-dropdown-trigger {
      transform: rotate(0deg);
      transition: transform .25s ease-in;
  }

  .p-dropdown.p-dropdown-open .p-dropdown-trigger {
      transform: rotate(180deg);
  }

  .p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon {
    color: rgba($color-grey-dark, .7);
    font-size: $font-size-18;
  }

  .p-dropdown-panel {
    outline: none;
    border: none;
    background: none;
    box-shadow: none;

    .p-dropdown-items {

      .p-dropdown-item {
        color: $color-white;
        font-size: $font-size-13;
        padding: $space-10;

        &:not(.p-highlight):not(.p-disabled):hover {
          background-color: $color-black !important;
          color: $color-primary;
        }

        &.p-highlight {
          color: $color-primary;
          background: none;

          &:after {
            color: $color-primary;
            font-size: $font-size-12;
          }
        }
      }

      .p-dropdown-item-group {
        color: $color-primary;
        border-bottom: 1px solid rgba($color-white, .4);
        background: transparent;
      }
    }
  }

  .p-dropdown-items-wrapper {
    border-radius: 16px;
    color: $color-white;
    background: $color-black;
    margin-top: 5px;
    box-shadow: 0 4px 14px 0 rgba(0,0,0,0.10);
    border-radius: $radius-16;
  }

  .p-dropdown .p-dropdown-label {
    color: $color-black;
    background-color: $form-button-background !important;
    padding: 12px;
  }

  // réduire le padding autour du dropdown Aides
  .form-control-lg {
    padding: 0.5rem !important;
  }
  // réduire le padding autour du dropdown Aides
  .p-dropdown:not(.p-disabled).p-state-focus {
    position: relative;
    border-color: $primary !important;
    box-shadow: 0 0 0 0.2rem rgba(251, 189, 48, 0.25);
  }

  .p-dropdown .p-highlight {
    position: relative;
    background-color: $color-black;

    &:after {
      content:'\e910';
      font-family: $font-icon;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      color: $color-white;
      font-size: $font-size-16;
    }
  }
}

.dropdown {
  .p-menu {
    position: absolute !important;
    left: -155px !important;
    &.p-menu-overlay {
      box-shadow: none;
      border: none;
    }
  }

  .p-menuitem.active .p-menuitem-link .p-menuitem-text::after {
    font-family: "icomoon", sans-serif !important;
    font-size: $font-size-14 !important;
    content: "\e910" !important;
    position: absolute !important;
    right: 0 !important;
    margin-right: 1em !important;
  }

  p-menu ul {
    border-radius: 12px;
    border: none;
    background: $color-black;

    .p-menuitem-link {
      background: none;
      &:not(.p-disabled):hover {
        background: none;
        color: $color-primary;

        .p-menuitem-text {
          color: $color-primary;
        }
      }

      &:focus {
        box-shadow: none;
      }

      .p-menuitem-text {
        color: $color-white;
      }
    }
  }
}

.p-inputtext {
  border: none !important;
  box-shadow: none !important;
  color: $body-color;

  input {
    min-width: 100%;
  }

  &::placeholder {
    color: rgba($color-black, .5);
  }

  &:hover {
    background-color: transparent;
  }
}

p-inplace {
  border: none !important;
  align-items: center;

  input {
    margin-top: -5px;
    min-width: 100%;
  }
}

div.form-group {
  margin-bottom: 0.5rem;
}

div.form-group.floating-label label {
  position: absolute;
  padding-top: 1.05rem;
  top: 0;
  left: 2px;
  padding-left: 0.5rem;
  color: $form-button-placeholder;
  transition: all 0.2s ease;
  font-family: $font-standard;
  font-style: normal;
  font-weight: normal;
  width: 95%;
}

div.form-group.floating-label .form-control.placeholder {
  opacity: 0;
  filter: alpha(opacity=0);
}

div.form-group.floating-label .form-control:-moz-placeholder {
  opacity: 0;
  filter: alpha(opacity=0);
}

div.form-group.floating-label .form-control::-moz-placeholder {
  opacity: 0;
  filter: alpha(opacity=0);
}

div.form-group.floating-label .form-control:-ms-input-placeholder {
  opacity: 0;
  filter: alpha(opacity=0);
}

div.form-group.floating-label .form-control::-webkit-input-placeholder {
  opacity: 0;
  filter: alpha(opacity=0);
}

div.form-group.floating-label .form-control:focus + label,
div.form-group.floating-label .form-control:not(:placeholder-shown) + label,
div.form-group.floating-label .form-control:valid + label {
  padding-top: 0.7rem;
  font-size: $font-size-11;
}

/*
p-inputNumber {
  background: red;
  .p-inputnumber {

  }

  .p-inputnumber-label {
    margin-bottom: $space-10;
    color: $color-grey-dark;
  }

  .p-inputnumber-input {
    padding: $space-10;
    background-color: $color-grey-light;
    border-radius: $radius-12;
    color: rgba($color-black, .5);
    width: calc(100% - 20px);

    &:focus {
      background-color: $color-grey-light;
      color: $color-black;
    }
  }
}*/

p-inputNumber {
  .p-inputnumber {
    width: 100%
  }

  .p-inputnumber-input {
    max-width: 100%;
    padding: 0;
    background: transparent;
  }
}

.input-number {
  display: block;
  position: relative;
  padding: $space-10 $space-40 $space-10 $space-10;
  background-color: $color-grey-light;
  border-radius: $radius-12;


  &:focus {
    background-color: $color-grey-light;
    color: $color-black;
  }

  &__suffix {
    position: absolute;
    top: 50%;
    right: $space-10;
    transform: translateY(-50%);
    color: $color-grey-default;
  }
}
