
.p-dialog.type-pieces {
  position: relative;
  width: 100vw;
  height: 100vh;
  border: none;
  box-shadow: none;

  &:before {
      content: "";
      position: absolute;
      bottom: 43px;
      left: 175px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 6px 0 6px;
      border-color: $color-white transparent transparent transparent;
  }

  .p-dialog-content {
    position: absolute !important;
    left: 45px;
    bottom: 50px;
    width: 275px;
    border: none;
    background: $color-white !important;
    border-radius: $radius-12;
  }

  .artiz-menu-list  {
    // TODO: Put header params to false and REMOVE &__header
    &__header {
      display: none;
    }

    &__items {
      overflow-y: hidden !important;
      max-height: auto !important;
    }

    &__item {
      margin-left: 0;
      margin-right: 0;
    }

    &__label,
    &__icon {
      color: $color-black;
    }
  }
}
