//----------time picker ---------//
.timepicker__header, .timepicker__body {
  background-color: #242432 !important;
}

.clock-face__number > span.active {
  background-color: $primary !important;
  color: $white !important;
}

.timepicker-button > span {
  font-size: $font-size-14;

  text-transform: uppercase;
  font-weight: 600;
  padding-left: 16px;
  padding-right: 16px;
  font-family: $font-standard;
  color: $primary !important;
}

.timepicker__actions {
  background-color: #242432 !important;
  border-top: 1px solid $form-button-placeholder;
}

.clock-face {
  background-color: #1b1b21 !important;
}

.clock-face__clock-hand {
  background-color: $primary !important;
}

.clock-face__number > span {
  color: $white !important;
}

.timepicker-dial__control {
  font-family: $font-standard !important;
  border: none !important;
  background-color: transparent !important;
  font-size: $font-size-16 !important;
  width: 70px !important;
  padding: 0px !important;
  border-radius: 0px !important;
  text-align: center !important;
}

.timepicker-dial__time {
  font-family: $font-standard !important;
  //color: var(--dial-inactive-color);
  font-size: $font-size-16 !important;
}

.clock-face__clock-hand_minute::before {
  border: 4px solid $primary !important;
  background-color: $primary !important;
}

//------Date Picker-------//

p-calendar > span > input {
  border: none !important;
  box-shadow: none !important;
  background: none !important;
}

.p-datepicker:not(.p-datepicker-inline) {
  background-color: $menu-bg;
  border: none;

  .p-datepicker-header {
    background-color: $menu-bg;
    border: none;

    select {
      background-color: $menu-bg;
      border: none;
      color: white;
      font-size: $font-size-16;
    }
  }

  table th {
    color: white;
    font-weight: normal;
    border-bottom: 1px solid grey;
  }

  table td {
    color: white;

    &.p-datepicker-today span {
      background-color: transparent;
      color: $primary;
    }

    span.p-highlight {
      background-color: $primary;
      border-radius: 50%;
    }
  }
}

@media screen and (max-width: 1042px) {

  body .p-datepicker:not(.p-datepicker-inline) {
    width: 100% !important;
    height: 100% !important;
    transform: translate(-50%, 0%) !important;
    bottom: 0 !important;
  }
}


app-color-picker {
  circle:hover {
    border: 1px solid;
    border: #FFBD30;
  }
}
