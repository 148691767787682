.artiz-card {
  display: flex;
  flex-direction: column;
  width: 280px;
  border-radius: $radius-16;
  background: $color-white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
  padding: $space-15;
  margin: $space-10;
  cursor: pointer;

  @media only screen and (min-width: 600px) {
    width: 292px;
  }

  &:focus{
    outline-color: $color-primary;
  }

  &:hover {
    background-color: $color-grey-medium;
  }

  &__name,
  &__title {
    font-family: $font-medium;
    font-size: $font-size-12;
    color: $color-grey;
    margin: 0;
  }

  app-coordonnees {
    margin: $space-10 0;
  }
}
