// IMPORT FONTS AND ICONS ICONOON //

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter-Medium";
  src: url("../assets/fonts/Inter-Medium.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: $font-semi-bold;
  src: url("../assets/fonts/Inter-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Inter-Bold";
  src: url("../assets/fonts/Inter-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Inter-Extra-Bold";
  src: url("../assets/fonts/Inter-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}

// Add to new file icon.scss ? //

@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?otzg0i');
  src: url('fonts/icomoon.eot?otzg0i#iefix') format('embedded-opentype'),
  url('fonts/icomoon.ttf?otzg0i') format('truetype'),
  url('fonts/icomoon.woff?otzg0i') format('woff'),
  url('fonts/icomoon.svg?otzg0i#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-select-plan-window-border:before {
  content: "\e9aa";
}
.icon-select-plan-door:before {
  content: "\e9a9";
}
.icon-select-vector-dashline-path:before {
  content: "\e9ac";
}
.icon-select-vector-line-path-bolder:before {
  content: "\e9ad";
}
.icon-select-plan-french-window:before {
  content: "\e9a8";
}
.icon-d-cube:before {
  content: "\e93a";
}
.icon-add-layer:before {
  content: "\e93b";
}
.icon-apartment:before {
  content: "\e93c";
}
.icon-app-settings:before {
  content: "\e93f";
}
.icon-apps:before {
  content: "\e940";
}
.icon-archive-folder:before {
  content: "\e941";
}
.icon-archive:before {
  content: "\e944";
}
.icon-attached-building-none:before {
  content: "\e948";
}
.icon-attached-building:before {
  content: "\e949";
}
.icon-barrel:before {
  content: "\e94a";
}
.icon-bell-filled:before {
  content: "\e94b";
}
.icon-book-open:before {
  content: "\e94c";
}
.icon-calculator:before {
  content: "\e94d";
}
.icon-camera:before {
  content: "\e94e";
}
.icon-certified:before {
  content: "\e94f";
}
.icon-clock:before {
  content: "\e950";
}
.icon-cloud:before {
  content: "\e951";
}
.icon-co2:before {
  content: "\e952";
}
.icon-comment:before {
  content: "\e953";
}
.icon-company:before {
  content: "\e954";
}
.icon-construction-house:before {
  content: "\e955";
}
.icon-currency-euro:before {
  content: "\e956";
}
.icon-cursor-select:before {
  content: "\e957";
}
.icon-dashboard-counter:before {
  content: "\e958";
}
.icon-data:before {
  content: "\e959";
}
.icon-diagnostic:before {
  content: "\e95a";
}
.icon-dimensions-house:before {
  content: "\e95b";
}
.icon-dimensions-map:before {
  content: "\e95c";
}
.icon-document:before {
  content: "\e95d";
}
.icon-duplicate:before {
  content: "\e95e";
}
.icon-ecology-globe-house:before {
  content: "\e95f";
}
.icon-energy-label:before {
  content: "\e9ab";
}
.icon-energy-results:before {
  content: "\e960";
}

.icon-environment-results:before {
  content: "\e961";
}
.icon-export:before {
  content: "\e962";
}
.icon-flash:before {
  content: "\e963";
}
.icon-gas:before {
  content: "\e964";
}
.icon-gauge:before {
  content: "\e965";
}
.icon-heater:before {
  content: "\e966";
}
.icon-home-family:before {
  content: "\e967";
}
.icon-home-info:before {
  content: "\e968";
}
.icon-home-print:before {
  content: "\e969";
}
.icon-hourglass:before {
  content: "\e96a";
}
.icon-house:before {
  content: "\e96b";
}
.icon-import:before {
  content: "\e96c";
}
.icon-inspect-house:before {
  content: "\e96d";
}
.icon-instructions-manual:before {
  content: "\e96e";
}
.icon-invoice:before {
  content: "\e96f";
}
.icon-layers:before {
  content: "\e970";
}
.icon-library:before {
  content: "\e971";
}
.icon-magnet:before {
  content: "\e972";
}
.icon-maintenance-schedule:before {
  content: "\e973";
}
.icon-maps-pin:before {
  content: "\e974";
}
.icon-minus:before {
  content: "\e975";
}
.icon-misc-1:before {
  content: "\e976";
}
.icon-move-segments:before {
  content: "\e977";
}
.icon-nuclear-bin:before {
  content: "\e978";
}
.icon-office-chair:before {
  content: "\e979";
}
.icon-percentage:before {
  content: "\e97a";
}
.icon-perpendicularity:before {
  content: "\e97b";
}
.icon-phone-action-search:before {
  content: "\e97c";
}
.icon-photos:before {
  content: "\e97d";
}
.icon-pipe:before {
  content: "\e97e";
}
.icon-plan-door:before {
  content: "\e97f";
}
.icon-plan-french-window:before {
  content: "\e980";
}
.icon-plan-roof-1-side:before {
  content: "\e981";
}
.icon-plan-roof-2-sides:before {
  content: "\e982";
}
.icon-plan-roof-3-sides-a:before {
  content: "\e983";
}
.icon-plan-roof-3-sides-b:before {
  content: "\e984";
}
.icon-plan-roof-4-sides-a:before {
  content: "\e985";
}
.icon-plan-roof-4-sides-b:before {
  content: "\e986";
}
.icon-plan-roof-4-sides-c:before {
  content: "\e987";
}
.icon-plan-vector-dashline-path:before {
  content: "\e988";
}
.icon-plan-vector-line-path-bolder:before {
  content: "\e989";
}
.icon-plan-vector-line-path:before {
  content: "\e98a";
}
.icon-plan-window:before {
  content: "\e98b";
}
.icon-plan:before {
  content: "\e98c";
}
.icon-print:before {
  content: "\e98d";
}
.icon-question1:before {
  content: "\e98e";
}
.icon-redo:before {
  content: "\e98f";
}
.icon-rename:before {
  content: "\e990";
}
.icon-renovation-plan:before {
  content: "\e991";
}
.icon-report:before {
  content: "\e992";
}
.icon-reverse:before {
  content: "\e993";
}
.icon-rotate-anti-clockwise:before {
  content: "\e994";
}
.icon-rotate-clockwise:before {
  content: "\e995";
}
.icon-ruler-triangle:before {
  content: "\e996";
}
.icon-ruler:before {
  content: "\e997";
}
.icon-select-side:before {
  content: "\e998";
}
.icon-select-surface:before {
  content: "\e999";
}
.icon-select:before {
  content: "\e99a";
}
.icon-sort:before {
  content: "\e99b";
}
.icon-thermometer-high:before {
  content: "\e99c";
}
.icon-thermometer-medium:before {
  content: "\e99d";
}
.icon-tools:before {
  content: "\e99e";
}
.icon-touch-finger:before {
  content: "\e99f";
}
.icon-touch-select:before {
  content: "\e9a0";
}
.icon-tree:before {
  content: "\e9a1";
}
.icon-undo1:before {
  content: "\e9a2";
}
.icon-uneditable:before {
  content: "\e9a3";
}
.icon-users:before {
  content: "\e9a4";
}
.icon-view:before {
  content: "\e9a5";
}
.icon-warning:before {
  content: "\e9a6";
}
.icon-wrench:before {
  content: "\e9a7";
}
.icon-piece:before {
  content: "\e946";
  color: #fbbd30;
}
.icon-mezzanine:before {
  content: "\e945";
  color: #fbbd30;
}
.icon-vide:before {
  content: "\e943";
  color: #ffbd30;
}
.icon-comble:before {
  content: "\e942";
  color: #fbbd30;
}
.icon-a-z:before {
  content: "\e947";
}
.icon-add-user:before {
  content: "\e906";
}
.icon-add:before {
  content: "\e907";
}
.icon-alphabetical:before {
  content: "\e908";
}
.icon-arrow-down:before {
  content: "\e909";
}
.icon-arrow-left:before {
  content: "\e90a";
}
.icon-arrow-right:before {
  content: "\e90b";
}
.icon-arrow-up:before {
  content: "\e90c";
}
.icon-avatar-client:before {
  content: "\e90d";
}
.icon-avatar:before {
  content: "\e90e";
}
.icon-bell:before {
  content: "\e900";
}
.icon-bulb:before {
  content: "\e901";
}
.icon-calendar:before {
  content: "\e90f";
}
.icon-chair_director:before {
  content: "\e902";
}
.icon-check:before {
  content: "\e910";
}
.icon-checkbox:before {
  content: "\e911";
}
.icon-chevron-down-select:before {
  content: "\e80c";
}
.icon-chevron-down:before {
  content: "\e912";
}
.icon-chevron-left:before {
  content: "\e913";
}
.icon-chevron-right:before {
  content: "\e914";
}
.icon-chevron-up:before {
  content: "\e915";
}
.icon-clear-input:before {
  content: "\e916";
}
.icon-conflit_pink:before {
  content: "\e93d";
  color: #f4306e;
}
.icon-context-menu:before {
  content: "\e917";
}
.icon-download:before {
  content: "\e939";
}
.icon-edit:before {
  content: "\e918";
}
.icon-envelope:before {
  content: "\e919";
}
.icon-error:before {
  content: "\e91a";
}
.icon-filter:before {
  content: "\e91b";
}
.icon-folder-add:before {
  content: "\e91c";
}
.icon-folder-sync:before {
  content: "\e91d";
}
.icon-folder:before {
  content: "\e91e";
}
.icon-folders:before {
  content: "\e91f";
}
.icon-home:before {
  content: "\e903";
}
.icon-hot:before {
  content: "\e93e";
}
.icon-id:before {
  content: "\e920";
}
.icon-info:before {
  content: "\e921";
}
.icon-jobs:before {
  content: "\e904";
}
.icon-lock:before {
  content: "\e922";
}
.icon-logout:before {
  content: "\e923";
}
.icon-menu:before {
  content: "\e924";
}
.icon-message:before {
  content: "\e905";
}
.icon-note-paper:before {
  content: "\e925";
}
.icon-paper-clip:before {
  content: "\e926";
}
.icon-pdf:before {
  content: "\e927";
}
.icon-phone-book:before {
  content: "\e928";
}
.icon-phone:before {
  content: "\e929";
}
.icon-pin:before {
  content: "\e92a";
}
.icon-question:before {
  content: "\e92b";
}
.icon-remove:before {
  content: "\e92c";
}
.icon-search:before {
  content: "\e92d";
}
.icon-send-mail:before {
  content: "\e92e";
}
.icon-settings:before {
  content: "\e92f";
}
.icon-share:before {
  content: "\e930";
}
.icon-star-fill:before {
  content: "\e931";
}
.icon-star:before {
  content: "\e932";
}
.icon-task-list:before {
  content: "\e933";
}
.icon-team:before {
  content: "\e934";
}
.icon-trash:before {
  content: "\e935";
}
.icon-undo:before {
  content: "\e936";
}
.icon-zoom-in:before {
  content: "\e937";
}
.icon-zoom-out:before {
  content: "\e938";
}
