a {
  color: green;
}

.link {
  color: $color-black;
  font-size: $font-size-12;
  text-decoration: none;
  opacity: 1;
  transition: opacity .25s ease;
  cursor: pointer;

  &--primary {
    color: $color-primary;//$primary !important;
  }

  &--secondary {
    color: green;//$primary !important;
  }

  &--tertiary {
    color: red;//$primary !important;
  }

  &--underline {
    text-decoration: underline;
  }

  &:hover {
    opacity: .6;
  }
}

.link-pass {
  padding-bottom: 3px;
  border-bottom: 1px solid rgb(23 37 36 / 10%);
  font-family: $font-standard;
}

.link-count-card {
  font-family: $font-medium;
  font-size:  $font-size-14;
}
