.menu {    // ---------------------------------------------- MENU DU BAS
  .p-menu {
    border: none;
    background-color: transparent;

    .p-menuitem-link {
      
      .p-menuitem-icon,
      .p-menuitem-text {
        color: $color-black;
      }
    }

    .p-menuitem-link:hover {
      background-color: transparent;

      .p-menuitem-icon,
      .p-menuitem-text {
        color: $color-white;
      }
    }
  }
}
