.artiz-app {
  position: relative;
  background-color: $menu-bg;
  overflow: hidden;
  touch-action: pan-y !important;

  &__overlay {
    top: 0;
    left:0;
    position: fixed;
    background-color: rgba(100, 100, 100, 0.2);
    width: 100%;
    height: 100%;
    z-index: 2500;
    display: none;
  }

  &__page {
    position: relative;
    left: 0;
    min-height: 100vh;
    background-color: $color-white;
    transition: all 0.5s ease;

    &.auth {
      // padding-top: $space-60;
      background-image: linear-gradient(0deg,#fba930 20%,#fbbd30);
      overflow: hidden;
    }

    &.etat-des-lieux {
      background-color: $body-bg;

      .artiz-panorama__footer {
        bottom: 0;
      }
    }
  }

  &__header {
    width: 100%;
    z-index: 1;
  }

  &__menu {
    position: fixed;
    top: 0;
    left: -100vw;
    width: 100vw;
    height: 100vh;
    background-color: $new-menu-bg;
    color: $body-dark-color;
    opacity: 1;
    transition: all 0.5s ease;
  }

  &__message {
    background: red;
    margin: 0 20px;
  }

  &__content {
    width: 100%;

    app-svg-def {
      position: absolute;
      height: 0px;
    }
  }

  &.is-menu-active {
    overflow: hidden;
    height: 100vh;

    .artiz-app__menu {
      left: 0;
    }

    .artiz-app__page {
      left: 90vw;
      height: calc(100vh - 40px);
      margin: 20px 0;
      border-radius: 20px;
      overflow: hidden;
    }
  }

  .loader {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background: rgba(36, 79, 99, 0.1);
    z-index: 2000;
  }

  &.is-loading {
    .artiz-app__loader {
      display: flex;
    }

    .artiz-app__overlay {
      display: block;
    }
  }
}

.artiz-spinner .p-progress-spinner-circle {
    stroke: $color-primary !important;
}
