@import 'variables';
@import 'mixins';
// @import "reset";
@import '../assets/fonts';
@import '~ol/ol.css';

// Import components //
@import 'components/auth';
@import 'components/box';
@import 'components/buttons';
@import 'components/card';
@import 'components/dialog';
@import 'components/forms';
@import 'components/items';
@import 'components/layout';
@import 'components/links';
// @import "components/list";
@import 'components/loader';
@import 'components/menu';
@import 'components/overlay';
@import 'components/panorama';
@import 'components/picker';
@import 'components/picture';
@import 'components/tabs';

/* --------------- GENERAL ---------------*/

body {
  font-family: $font-medium;
  font-size: $font-size-14;
  text-decoration: none;
  margin: 0;
  background: $menu-bg;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

button {
  outline: none;
}

.action-add {
  display: flex;
}

.is-menu-active .action-add {
  display: none;
}

.p-sidebar-mask.p-component-overlay {
  background: rgba($color-white, 0.75);

  &--dark {
    background: rgba($color-black, 0.4);
  }
}

// Supprime le petit bandeau "Cookie" qui reste en bas à droite
.cc-color-override-1899226980.cc-revoke {
  display: none;
}
