.artiz-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: calc(100vh - 60px);
    //height: 100%;
    //min-height: calc(100vh - 60px);
    background-color: $color-grey-light;

    &__container {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        max-width: 624px;
        padding: 10px 0 80px 0;


        @media only screen and (min-width: 460px) {
          justify-content: flex-start;
        }
    }
  }
