.artiz-box {
  background: $color-white;
  border-radius: $radius-16;

  &--link {
    .artiz-box__content {
      margin-top: 0;
      padding-top: 0;
      border-top: none;
    }
  }

  &--expand {
    .artiz-box__header {
      padding-right: $space-50;
    }
  }

  &--selected {
    border: 2px solid $color-primary;
  }

  &--data {
    .artiz-box__item {
      margin: 0 -15px $space-10 -15px;
      padding: 0 15px $space-10 15px;
      border-bottom: 1px solid rgba($color-black, .1);

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
      }
    }

    .artiz-box__paragraph {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__header,
  &__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__header {
    position: relative;
    padding: $space-20 $space-15;

    h3, p {
      margin: 0;
    }

    &--block {
      display: block;

      .artiz-box__subtitle {
        margin-bottom: $space-10 !important;
      }

      .artiz-box__paragraph {
        font-size: $font-size-18;
        color: $color-black;
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $space-20 $space-15;
    border-top: 1px solid rgba($color-black, .1);
  }

  &__title,
  &__link {
    font-size: $font-size-14;
    color: $color-black;
  }

  &__link {
    justify-content: flex-start;

    app-icon {
      margin: 0 $space-10 0 $space-5;
    }

    &--between {
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__subtitle {
    font-size: $font-size-12;
    color: $color-black;
  }

  &__paragraph {
    font-size: $font-size-12;
    color: $color-grey;

    &--green {
      color: $color-success;
    }

    &--red {
      color: $color-danger;
    }

    &--between {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &--border-bottom {
      margin: 0 -15px $space-10 -15px;
      padding: 0 $space-15 $space-10 $space-15;

      border-bottom: 1px solid rgba($color-black, .1);
    }
  }

  &__state {
    position: absolute;
    top: 50%;
    right: 45px;
    transform: translateY(-50%);
    width: 7px;
    height: 7px;
    border-radius: $radius-circle;
    background: $color-grey-light;
  }

  &__toggle {
    position: absolute;
    right: 15px;
    top: 20px;
    color: $color-grey;
    font-size: $font-size-22;
    transform: rotate(0deg);
    transition: transform .25s ease;

    &--notification {
      &:before {
        content: '';
        position: absolute;
        top: calc(50% + 0px);
        left: -20px;
        transform: translateY(-50%);
        display: block;
        width: 8px;
        height: 8px;
        border-radius: $radius-circle;
        background-color: $color-grey;
      }
    }

    &--red {
      &:before {
        background-color: $color-danger;
      }
    }

    &--orange {
      &:before {
        background-color: $color-warning;
      }
    }

    &--green {
      &:before {
        background-color: $color-success;
      }
    }
  }

  &__content {
    padding: $space-15;
    border-top: 1px solid rgba($color-black, .1);

    &--multi {

      .artiz-box__list {
        width: 50%;
      }

      .artiz-box__item {
      }
    }

    &--full {

      .artiz-box__list {
        width: calc(100% + 30px);
        margin: 0 -15px $space-10 -15px;
        padding: 0 $space-15 $space-10 $space-15;
        border-bottom: 1px solid rgba($color-black, .1);

        &:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
          border-bottom: none;
        }
      }

      .artiz-box__label {
        font-size: $font-size-14;
        color: $color-black;
        margin-bottom: $space-15;
      }

      .artiz-box__item {
        margin: 0;
        padding: 0;

        &--between {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .artiz-box__label {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &__list {
    margin: -10px 0;

    .artiz-box__paragraph {
      line-height: $font-size-18;
    }

    .artiz-box__subtitle {
      margin-bottom: $space-20;

      &--between {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: center;

      li {
        width: calc(50% - 20px);
        margin: 0 $space-10;

        &:first-child {
          text-align: right;
        }
      }
    }
  }

  &__item {
    margin: 10px 0;
  }

}
