.editable {
  .picture-wrapper {
    .element-selector {
      display: block;
    }
  }
}

.picture-group {
  display: flex;
  flex-flow: row wrap;
  padding: 0 5px;

  .picture-wrapper {
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(56, 63, 65, 0.6);
    }

    &.selected {
      &::before {
        background: rgba(188, 188, 188, 0.8);
      }

      .element-selector {
        background: $color-primary;
        color: $color-grey;

        i {
          display: inline-block;
          font-size: $font-size-10;
        }
      }
    }
  }
  .element-selector {
    display: none;
    position: absolute;
    bottom: 5px;
    right: 5px;
    width: 18px;
    height: 18px;
    background: $black;
    text-align: center;
    line-height: 18px;
    border-radius: 50%;
    transition: background 0.3 ease-in;

    i {
      display: none;
    }
  }
}


.picture-wrapper {
  position: relative;
  display: inline-block;
  margin: 5px;
  flex: 0 0 21%;
  width: 67px;
  height: 67px;
  overflow: hidden;
  border-radius: 5px;

  .picture-thumbnail {
    display: inline-block;
    width: 100%;
    height: 100%;
    background-size: cover;
  }

  @include media-breakpoint-up(md) {
    width: 98px;
    height: 98px;
    margin: 10px;
  }
}
