.artiz-tabs {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  overflow-y: hidden;
  width: calc(100% + $space-10);
  overflow-x: scroll;
  background: $color-white;
  padding: 0 0 0 8px;
  margin: 0 calc($space-10 * -1);

  @media only screen and (min-width: 768px) {
    padding: 0 0 0 90px;
    overflow-x: auto;
  }

  &:after {
    @media only screen and (max-width: 460px) {
      content: '';
      position: fixed;
      right: 0;
      width: 50px;
      height: 50px;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
    }
  }

  &__item {
    display: flex;
    align-items: center;
    height: 50px;
    background: $color-white;
    margin: 0 $space-10;
    cursor: pointer;

    &.selected,
    &.active {
      cursor: default;

      a {
        color: $color-primary !important; // App.scss line:32 !important color on a tag to remove
      }
    }

    &.short-last {
      padding-right: 40px;
    }
  }

  &__link {
    font-family: $font-medium;
    font-size: $font-size-14;
    line-height: $font-size-18;
    color: $color-grey;
  }
}

// TODO to remove ater check all tabs

/* --------------- TABVIEW ---------------*/

p-tabview {
  .p-tabview {
    .p-tabview-nav {
      display: flex;
      flex-flow: row nowrap;
      overflow-y: hidden;
      overflow-x: scroll;
      width: 100%;
      background: $color-white;
      padding: 0 0 0 8px;
      margin: 0 calc($space-10 * -1);

      @media only screen and (min-width: 768px) {
        padding: 0 0 0 90px;
      }

      &:after {
        @media only screen and (max-width: 460px) {
          content: '';
          position: fixed;
          right: 0;
          width: 50px;
          height: 50px;
          background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 100%
          );
        }
      }

      .p-tabview-nav-link {
        display: flex;
        align-items: center;
        margin: 0 $space-10;
        padding: 0;
        border-color: transparent !important;
        background-color: $color-white !important;
        font-family: $font-medium;
        font-size: $font-size-14;
        line-height: $font-size-18;
        color: $color-black !important;
        white-space: nowrap;
        height: 50px;
        box-shadow: none !important;

        &:hover {
          opacity: 1 !important;
        }

        .p-tabview-title {
          cursor: pointer !important;
        }
      }

      .p-disabled {
        opacity: 1;
        cursor: default !important;

        .p-tabview-title {
          cursor: default !important;
        }
      }

      li.p-highlight a {
        color: $color-primary !important;
        cursor: default;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

/*.filter-tabs {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
    background: $white;
    padding: 0 0 0 20px;

    @media only screen and (min-width: 768px) {
        padding: 0 0 0 80px;
    }

    .filter-tab {
        display: flex;
        align-items: center;
        height: 50px;
        background: $white;

        a {
            padding: 0 0.8125em;
            color: $card-font;
            font-family: $font-medium;
            font-size: $font-size-15;
            font-weight: 400;
            line-height: 18px;
            cursor: pointer;
        }

        &:first-child {
            a {
                padding: 0 0.8125em 0 0;
            }
        }

        &:last-child {
            a {
                padding: 0 2em 0 0.8125em;
            }
        }
    }

    .filter-tab.selected a {
        color: $primary !important;
    }
}

.white-gradient {
    position: absolute;
    top: $space-60;
    right: 0;
    width: 50px;
    height: 50px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}*/
