// Button & Input in auth page //

.btn {
  outline: none;
  box-shadow: none;
  border: none;
  border-radius: $radius-8;
  appearance: none;
  cursor: pointer;

  &--primary {
    background-color: $color-primary;
    color: $color-black;
    font-size: $font-size-14;

    &:enabled,
    &:hover,
    &:focus {
      border: none;
      background: $color-primary;
      box-shadow: none;
    }

    &:enabled {
      &:hover {
        color: $color-black;
        background: $color-primary;
        border: none;
      }
    }
  }


  &--secondary {
    background-color: $color-black;
    color: $color-white;
    font-size: $font-size-14;

    &:enabled,
    &:hover,
    &:focus {
      border: none;
      background: $color-black;
      box-shadow: none;
    }

    &:enabled {
      &:hover {
        color: $color-white;
        background: $color-black;
        border: none;
      }
    }
  }

  &--delete {
    background-color: $color-black;
    color: $color-white;
    font-size: $font-size-14;
    padding: $space-10;

    .icon {
      font-size: $font-size-24;
      color: $color-white;
    }
  }

  &--full {
    width: 100%;
  }

  &--large {
    border-radius: $radius-12;
    padding: 12px 18px;
  }

  &--rounded {
    border-radius: $radius-circle;

    .p-button {
      border-radius: $radius-circle;
    }
  }
}

.btn-delete {
  .p-button-label {
    display: none;
  }
}

.btn-filter {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 20px;
  border-radius: $radius-circle;
  background-color: $color-black;
  color: $color-white;

  .dot-notif {
    position: absolute;
    top: 11px;
    right: 15px;
    width: 6px;
    height: 6px;
    border: 2px solid $color-black;
  }
}

.btn {
  .p-button {
    outline: none;
    box-shadow: none;
    border: none;
    border-radius: $radius-8;
    appearance: none;
  }

  &--primary {
    .p-button {
      background-color: $color-primary;
      color: $color-black;
      font-size: $font-size-14;

      &:enabled,
      &:hover,
      &:focus {
        border: none;
        background: $color-primary;
        box-shadow: none;
      }

      &:enabled {
        &:hover {
          color: $color-black;
          background: $color-primary;
          border: none;
        }
      }
    }
  }

  &--secondary {
    .p-button {
      background-color: $color-black;
      color: $color-white;
      font-size: $font-size-14;

      &:enabled,
      &:hover,
      &:focus {
        border: none;
        background: $color-black;
        box-shadow: none;
      }

      &:enabled {
        &:hover {
          color: $color-white;
          background: $color-black;
          border: none;
        }
      }
    }
  }
}

// add from app.scss //

button {
  box-shadow: none;
}

button.p-button-raised {
  border-color: $primary;
  background-color: $primary;
}

button.p-button-raised:enabled:hover {
  border-color: $primary;
  background-color: $primary-hover;
}

.p-selectbutton {
  margin: -4px -2px;
}

.p-selectbutton .p-button {
  background: $color-grey-light;
  color: $color-black;
  font-size: $font-size-14;
  border: none;
  margin: 4px 2px !important;
  border-radius: 12px !important;
}

.p-selectbutton .p-button.p-highlight {
  background: $color-black;
  color: $color-white;
  border: none;
}

.p-selectbutton .p-button.p-highlight:hover {
  background: $color-black;
  color: $color-white;
  border: none;
  box-shadow: 0 0 0 0.05rem $color-primary;
}

// end add from app.scss //
// TODO To remove if no impact on #app-page
//
// #app-page {
//
//   .p-button-rounded {
//     width: 100%;
//     color: $white;
//     background-color: $form-button-font;
//     border-color: $form-button-font;
//     line-height: 1.5;
//     border-radius: 0.7rem;
//     box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.13);
//     display: inline-block;
//     padding: 0.3rem 1rem;
//     transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
//     margin-bottom: 0.5rem;
//     margin-top: 1rem;
//     font-size: $font-size-14;
//   }
//
//   p-selectbutton {
//
//     .p-button.p-button-text-only .p-button-text {
//       padding: 0.90em 2.5em !important;
//     }
//
//
//     .p-selectbutton .p-button {
//       background-color: $color-grey-light;
//       border-radius: 1em;
//       border: none;
//       color: $form-button-select;
//
//       span:hover {
//         color: $disabled !important;
//       }
//     }
//
//     .p-selectbutton .p-button.p-highlight {
//       background-color: $form-button-select;
//       color: $form-button-background;
//       outline: none;
//     }
//
//     .p-button {
//       margin-right: 3px !important;
//       border: 1px solid transparent;
//       transition: border .25s ease;
//     }
//
//     .p-button:focus {
//       outline: 0 none;
//       outline-offset: 0;
//       box-shadow: 0 0 0 0.2rem $color-primary;
//       border: 1px solid $color-primary;
//     }
//
//   }
// }

p-button {
  .ui-button {
    font-family: $font-semi-bold, sans-serif !important;
    color: $form-button-font !important;
    background-color: $primary !important;
    padding: 0.2em !important;
    border: none !important;

  }
}

/* ol button */

.ol-control {
  background: transparent;
  padding: 0;
}

.ol-touch .ol-control button {
  font-size: $font-size-16;
}

.ol-control button {
  position: relative;
  width: 25px;
  height: 25px;
  font-family: $font-medium;
  font-size: 1em;
  color: $color-black;
  background-color: transparent;
  outline: none;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 25px;
    height: 25px;
    z-index: -10;
    border-radius: $radius-circle;
    background-color: $color-grey-light;
  }

  &:after {
    content: '';
    position: absolute;
    top: 1px;
    left: 50%;
    z-index: -100;
    width: 5px;
    height: 5px;
    background-color: $color-danger;
    transform: rotate(45deg) translateX(calc(-50% - 2px));
  }
}
