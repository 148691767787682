///// FONTS ////////

$font-standard: "Inter", "Open Sans", "Helvetica Neue", sans-serif;
$font-medium: "Inter-Medium";
$font-semi-bold: "Inter-Semi-Bold";
$font-icon: "icomoon";

$font-size-5: 0.3125rem; // 5px
$font-size-7: 0.4375rem; // 7px
$font-size-10: 0.625rem; // 10px
$font-size-11: 0.6875rem; // 11px
$font-size-12: 0.75rem; // 12px
$font-size-13: 0.8125rem; // 13px
$font-size-14: 0.875rem; // 14px
$font-size-15: 0.9375rem; // 15px
$font-size-16: 1rem; // 16px
$font-size-18: 1.125rem; // 18px
$font-size-20: 1.250rem; // 20px
$font-size-22: 1.375rem; // 22px
$font-size-24: 1.5rem; // 24px
$font-size-26: 1.625rem; // 26px
$font-size-28: 1.75rem; // 28px
$font-size-30: 1.875rem; // 30px
$font-size-40: 2.5rem; // 40px

//////////////////////

//////// COLORS ////////

/* Design System Colors */

/* Colors Main */
$color-black: #323141; // $menu-bg $body-color
$color-white: #FFFFFF; // $white
$color-transparent: transparent;
$color-primary: #FBBD30; // $primary
$color-primary-dark: #18B0A5;
$color-disabled: #D8D8D8; // $form-slider-background
$color-disabled-darker: #959595;
$color-success: #1ECD4F;
$color-warning: #FFA300;
$color-danger: #F4306E; // $pink
$color-missing: #8C42DD;
$color-completed: #27BAFF;

$color-grey: #4E6A68; // $form-label && $ $card-font
$color-grey-default: #ccc; //$disabled
$color-grey-light: #f0eefa; //body-bg
$color-grey-regular: #dbd8e9;
$color-grey-medium: #f4f4f7;
$color-grey-dark: #75868F;

$color-card-hover: #f4f4f7;

/* $color-grey lighten */
$color-grey-lighten-10: lighten( $color-grey , 10% );
$color-grey-lighten-20: lighten( $color-grey , 20% );
$color-grey-lighten-30: lighten( $color-grey , 30% );
$color-grey-lighten-40: lighten( $color-grey , 40% );
$color-grey-lighten-50: lighten( $color-grey , 50% );
$color-grey-lighten-60: lighten( $color-grey , 60% );
$color-grey-lighten-70: lighten( $color-grey , 70% );
$color-grey-lighten-80: lighten( $color-grey , 80% );
$color-grey-lighten-90: lighten( $color-grey , 90% );

/* $color-grey darken */
$color-grey-darken-10: darken( $color-grey , 10% );
$color-grey-darken-20: darken( $color-grey , 20% );
$color-grey-darken-30: darken( $color-grey , 30% );
$color-grey-darken-40: darken( $color-grey , 40% );
$color-grey-darken-50: darken( $color-grey , 50% );
$color-grey-darken-60: darken( $color-grey , 60% );
$color-grey-darken-70: darken( $color-grey , 70% );
$color-grey-darken-80: darken( $color-grey , 80% );
$color-grey-darken-90: darken( $color-grey , 90% );

/* Color Gradient */
$color-gradient-folders: linear-gradient(180deg, #3BDBA7 0%, #3BDB8D 99%);
$color-gradient-messages: linear-gradient(180deg, #4B63FF 2%, #6659FF 100%);
$color-gradient-agenda: linear-gradient(180deg, #FF805C 0%, #F23C5B 100%);
$color-gradient-contact: linear-gradient(180deg, #D360FF 0%, #8B49FF 100%);
$color-gradient-jobs: linear-gradient(180deg, #1CDAEA 0%, #1CEAC1 100%);
$color-gradient-tasks: linear-gradient(180deg, #265769 0%, #3D387D 100%);
$color-gradient-management: linear-gradient(180deg, #FF367B 0%, #FF3640 100%);
$color-gradient-notes: linear-gradient(180deg, #F3C350 0%, #F37E50 100%);

/* Custom Colors */
$color-electricite:   #5834D5;
$color-gaz:           #FF3B91;
$color-bois:          #2C1872;
$color-autre:         #E2DBFF;

////////////////////////

/* OLD VARIABLES COLORS TO REFACTO */

/* MAIN Colors */

$primary: #fbbd30;
$primary-hover: #fbc850;
$disabled: #cccccc;
$red: #cc0066;
$danger: #EB1C49;
$white: #fff;
$black: #000;

/* BODY colors */

$menu-bg: #323141;
$new-menu-bg: #242332; // ce n'était pas le bon bg sur le menu
$body-bg: #f0eefa;
$body-color: #172524;
$body-dark-bg: #333333;
$body-dark-color: #eeeeee;

/* FORM colors */
$form-button-font: #062c2a;
$form-button-background: #f4f4f7;
$form-button-focus: #fbbd30;
$form-button-placeholder: #718786;
$form-button-disabled: #b8c3c2;
$form-button-select: #172625;
$form-label: #4E6A68;
$form-slider-background: #D8D8D8;

/* OTHERS colors */

$navigation : #323241;
$card-font  : #4e6a68;
$pink  : #f4306e;
$panorama-background: #444444;
$search-cursor: #2d88ff;
$border:#3f3e4d;

/* END VARIABLES COLORS TO REFACTO */

//////// SPACINGS ////////
$space-5:     5px;
$space-10:    10px;
$space-15:    15px;
$space-20:    20px;
$space-25:    25px;
$space-30:    30px;
$space-40:    40px;
$space-50:    50px;
$space-60:    60px;
$space-80:    80px;

//////// RADIUS ////////
$radius-4:      4px;
$radius-6:      6px;
$radius-8:      8px;
$radius-10:      10px;
$radius-12:      12px;
$radius-16:     16px;
$radius-18:     18px;
$radius-20:     20px;
$radius-24:     24px;
$radius-28:     28px;
$radius-32:     32px;
$radius-circle: 50%;

///// OPTIONS ////////


// Quickly modify global styling by enabling or disabling optional features.
$enable-caret:              true !default;
$enable-rounded:            true !default;
$enable-shadows:            false !default;
$enable-gradients:          false !default;
$enable-transitions:        true !default;
$enable-grid-classes:       true !default;
$enable-print-styles:       true !default;



///// BREAKPOINTS ////////
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

$width-input: 90vw;
$height-input: 42px;

$height-navigation: 60px;