// Button & Input in auth page //

.artiz-auth {
  width: 100%;
  padding: 1.2rem;

  &__title {
    margin-bottom: 1.5rem;
    font-family: $font-semi-bold;
    font-size: $font-size-18;
    color: $body-color;
  }

  &__subtitle {
    margin-bottom: 3rem;
    text-align: center;
    margin-left: 3.5em;
    margin-right: 3.5em;
  }

  &__logo {
    width: 7.5rem;
    margin-bottom: 1rem;
  }

  &__illustration {
    margin: auto;
    margin-top: 3rem;
    width: 45% ;
  }

  .forgotten-pass {
    border-bottom: 1px solid rgba(23, 37, 36, .25);
    margin-bottom: 0.2rem;
    font-size: $font-size-12;
  }

  &.artiz-auth-create {
    margin-right: 0.4rem;
  }

  &.artiz-auth-reset {
    .artiz-auth__title {
      margin-top: 2rem;
      margin-bottom: 2rem;
      text-align: center;
    }
  }
}

.aritz-auth-signin, .artiz-auth-reset {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}


@media only screen
and (min-device-width: 768px)
// and (max-device-width: 1024px)
and (-webkit-min-device-pixel-ratio: 2) {
  .artiz-auth{
    &__logo {
      margin-bottom: 3rem;
    }

    &__illustration {
      // margin-top: 9rem;
    }
  }
}

.artiz-auth {

  .form-group.floating-label {
    position: relative;
  }

  .form-group.floating-label label {
    position: absolute;
    padding-top: 1.05rem;
    top: 0px;
    left: 2px;
    padding-left: 0.5rem;
    color: $form-button-placeholder;
    transition: all 0.2s ease;
    font-family: $font-standard;
    font-style: normal;
    font-weight: normal;
    width: 95%;
  }

  .form-group.floating-label .form-control {
    border: none;
    background: $color-white;
    width: 100%;
    border-radius: 12px;
    padding-top: 1.5rem;
    padding-bottom: 0.6rem;
    transition: all 0.2s ease;
    outline: none;

    label {
      display: flex;
      align-items: center;
    }
  }

  .form-group.floating-label .form-control.placeholder {
    opacity: 0;
    filter: alpha(opacity=0);
  }

  .form-group.floating-label .form-control:-moz-placeholder {
    opacity: 0;
    filter: alpha(opacity=0);
  }

  .form-group.floating-label .form-control::-moz-placeholder {
    opacity: 0;
    filter: alpha(opacity=0);
  }

  .form-group.floating-label .form-control:-ms-input-placeholder {
    opacity: 0;
    filter: alpha(opacity=0);
  }

  .form-group.floating-label .form-control::-webkit-input-placeholder {
    opacity: 0;
    filter: alpha(opacity=0);
  }

  .form-group.floating-label .form-control:focus + label,
  .form-group.floating-label .form-control:valid + label,
  .form-group.floating-label .form-control:not(:placeholder-shown) + label {
    padding-top: 0.7rem;
    font-size: $font-size-11;
  }

  a.forgotten-pass {
    text-decoration:  none;
  }

  input.form-control-lg {
      padding-top: 1.5rem;
      padding-bottom: .6rem;
      transition: all .2s ease;
      padding: 1.05rem .6rem;
      font-size: .9rem;
      line-height: 1.5;
      border-radius: .7rem;
  }

  .input-icon {
    top: 20px;
  }
}

//
// //datepicker create
// #app-page.auth p-calendar {
//
//   .p-calendar {
//     width: 100%;
//   }
//
//   .p-inputtext {
//     //form-control
//     display: block;
//     width: 100%;
//     color: $form-button-font;
//     background-color: $form-button-background;
//     background-clip: padding-box;
//     border: 2px solid $form-button-background;
//     border-radius: 0.7rem;
//     transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
//
//     //form-control-lg
//     padding: 1.05rem 0.5rem;
//     font-size: $font-size-15;
//     line-height: 1.5;
//
//     //shadow
//     box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
//   }
//
//   .p-datepicker-current-day {
//     background-color: $primary;
//   }
//
//   .p-datepicker-today {
//     background-color: $primary;
//   }
//
//   .p-datepicker-inline {
//     background-color: red;
//   }
//
//   .ui-calendar-w-btn {
//     background-color: $primary;
//   }
// }
//
//
// #app-page.auth p-inputmask {
//   .p-inputtext {
//     //form-control
//     display: block;
//     width: 100%;
//     color: $form-button-font;
//     background-color: $form-button-background;
//     background-clip: padding-box;
//     border: 2px solid $form-button-background;
//     border-radius: 0.7rem;
//     transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
//
//     //form-control-lg
//     padding: 1.05rem 0.5rem;
//     font-size: $font-size-15;
//     line-height: 1.5;
//
//     //shadow
//     box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
//
//   }
//
//   //enlever la ligne du bas
//   .ui-state-filled {
//     text-transform: none;
//     text-decoration: none;
//   }
//
// }
//
// #app-page.auth app-adresse-gouv-field {
//
//   .ui-widget {
//     width: 100%;
//
//   }
//
//   .p-inputtext {
//     //form-control
//     display: block;
//     width: 100%;
//     color: $form-button-font;
//     background-color: $form-button-background;
//     background-clip: padding-box;
//     border: 2px solid $form-button-background;
//     border-radius: 0.7rem;
//     transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
//
//     //form-control-lg
//     padding: 1.05rem 0.5rem;
//     font-size: $font-size-15;
//     line-height: 1.5;
//
//     //shadow
//     box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
//   }
// }
